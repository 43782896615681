import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Shadow } from '@pitchero/react-ui';
import { getCurrentClubIncludesSkins } from 'store/clubs/selectors';
import { SKINNED_WIDTH } from 'lib/advertising/constants';
import isServer from 'lib/is-server';

const Container = ({ children, fullWidth, leftOffset, skinsDisplayed, topAdjustment }) => {
  const fullWidthSize = skinsDisplayed ? SKINNED_WIDTH : '100vw';

  // When skins are displayed we need to move in from the left to align with the page container
  let leftPosition = leftOffset * -1;
  const pageConstrictedBySkins =
    skinsDisplayed && !isServer() && window && window.innerWidth > SKINNED_WIDTH;
  if (pageConstrictedBySkins) {
    leftPosition += (window.innerWidth - SKINNED_WIDTH) / 2;
  }

  return (
    <Cushion
      horizontal="medium"
      component="div"
      style={{
        position: 'absolute',
        top: `calc(100% + ${topAdjustment}px)`,
        left: fullWidth ? leftPosition : null,
        right: fullWidth ? null : 0,
        zIndex: 1,
        width: fullWidth ? fullWidthSize : 'auto',
        boxSizing: 'border-box',
      }}
    >
      <Shadow shadow="0 0px 20px 0 rgba(0,0,0,0.25)">
        <Rectangle fill="white" radius="rounded">
          <Cushion horizontal="medium" vertical="large" component="div">
            {children}
          </Cushion>
        </Rectangle>
      </Shadow>
    </Cushion>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  leftOffset: PropTypes.number.isRequired,
  skinsDisplayed: PropTypes.bool.isRequired,
  topAdjustment: PropTypes.number.isRequired,
};
Container.defaultProps = {
  fullWidth: false,
};

const mapStateToProps = (state) => ({
  skinsDisplayed: getCurrentClubIncludesSkins(state),
});

export default connect(mapStateToProps)(Container);
