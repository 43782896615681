import React from 'react';
import PropTypes from 'prop-types';
import SecondaryPanel from './secondary-panel';

const LevelTwo = ({ setLevelThree, ...panelProps }) => {
  return (
    <SecondaryPanel
      {...panelProps}
      onClickWithOptions={(item) =>
        setLevelThree({
          isOpen: true,
          props: { ...item },
        })
      }
    />
  );
};

LevelTwo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setLevelThree: PropTypes.func.isRequired,
};
export default LevelTwo;
