import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, Panel, Rectangle, ScrollBox, Typography } from '@pitchero/react-ui';
import BackArrowIcon from 'icons/BackArrow';
import IconWithTheme from 'components/icon-with-theme';
import { Trans } from 'lib/i18n';
import NavItem from './nav-item';

const SecondaryPanel = ({
  isOpen,
  onClose,
  onBackClick,
  props: { name, groupedOptions, options, transKey },
  onClickWithOptions,
  ...otherProps
}) => {
  const items = groupedOptions || options;
  return (
    <Rectangle fill="white">
      <Panel isOpen={isOpen} onClose={onClose} {...otherProps}>
        <Grid rows="auto minmax(0, 1fr)" style={{ maxHeight: '100%' }}>
          <Cushion vertical="medium" left="large" right="xxxlarge" component="div">
            <Typography
              preset="name"
              color="black"
              onClick={onBackClick}
              onKeyPress={onBackClick}
              aria-label="back"
              role="button"
              tabIndex={-1}
              style={{ cursor: 'pointer' }}
            >
              <IconWithTheme>
                <BackArrowIcon color="black" topOffset={2} />
              </IconWithTheme>
              <Cushion left="medium" component="span">
                {transKey ? <Trans i18nKey={transKey} /> : name}
              </Cushion>
            </Typography>
          </Cushion>
          <div>
            <ScrollBox yOnly>
              <Cushion horizontal="large" bottom="large" component="div">
                {items &&
                  items.map((item, i) => {
                    const isLastItem = items.length === i + 1;
                    if (item.groupedOptions || item.options) {
                      return (
                        <NavItem
                          {...item}
                          key={item.id}
                          noSeperator={isLastItem}
                          onClick={() => onClickWithOptions({ ...item })}
                        />
                      );
                    }
                    return (
                      <NavItem {...item} noSeperator={isLastItem} onClose={onClose} key={item.id} />
                    );
                  })}
              </Cushion>
            </ScrollBox>
          </div>
        </Grid>
      </Panel>
    </Rectangle>
  );
};

SecondaryPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  onClickWithOptions: PropTypes.func,
  props: PropTypes.shape(),
};
SecondaryPanel.defaultProps = {
  onClickWithOptions: () => null,
  onBackClick: () => null,
  props: {},
};
export default SecondaryPanel;
