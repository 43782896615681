import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, values, mapValues, isEmpty, indexOf } from 'lodash';
import { FlexContainer, withTheme } from '@pitchero/react-ui';
import breakpoints from 'lib/club-theme/breakpoints';
import { Trans } from 'lib/i18n';
import getNavItems from '../nav-items';
import NavItem from './nav-item';
import NavBarContainer from './container';

const NavBar = ({ activeSection, club, uiTheme }) => {
  const [navItems, setNavItems] = useState(getNavItems({ activeSection, club, limitTeams: true }));
  const [moreWidth, setMoreWidth] = useState(null);
  const [containerDimensions, setContainerDimensions] = useState({ height: 60, width: 0 });
  const { height: containerHeight, width: containerWidth } = containerDimensions;

  const containerRef = useRef(null);
  const navItemRefs = mapValues({ ...navItems }, () => React.createRef());
  const moreRef = useRef(null);

  let remainingWidth = containerWidth - moreWidth;
  const moreItems = [];

  const updateContainerDimensions = () => {
    if (containerRef.current) {
      const newContainerHeight = containerRef.current.clientHeight;
      const newContainerWidth = containerRef.current.clientWidth;
      if (containerWidth !== newContainerWidth || containerHeight !== newContainerHeight) {
        setContainerDimensions({ height: newContainerHeight, width: newContainerWidth });
      }
    }
  };

  // Set event listeners when the component first mounts.
  useEffect(() => {
    updateContainerDimensions();
    window.addEventListener('resize', updateContainerDimensions);
    return function callback() {
      window.removeEventListener('resize', updateContainerDimensions);
    };
  }, []);

  // If the window is smaller than the `fullHeader` breakpoint we will be showing the mobile nav and not this
  const { fullHeader } = breakpoints;
  const isVisible = typeof window !== 'undefined' && window.innerWidth >= fullHeader;

  // Items that don't fit on the screen cannot be set to display none
  // because we will still need its width for calculations.
  const hiddenStyles = {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
  };

  // When the container width changes work out which navItems to show.
  useEffect(() => {
    if (isVisible) {
      const newNavItems = mapValues({ ...navItems }, (navItem) => {
        return { ...navItem, width: navItemRefs[navItem.id].current.clientWidth };
      });
      setNavItems(newNavItems);
      setMoreWidth(moreRef.current.clientWidth);
    }
  }, [containerWidth]);

  return (
    <NavBarContainer className="primary-nav" fill={get(uiTheme, 'colors.navBg')}>
      <div ref={containerRef}>
        {isVisible && (
          <FlexContainer alignItems="center" justifyContent="space-around">
            {values(navItems).map((navItem) => {
              remainingWidth -= navItem.width;
              const hidden = remainingWidth <= 0;
              if (hidden) {
                moreItems.push(navItem.id);
              }
              return (
                <div
                  key={navItem.id}
                  ref={navItemRefs[navItem.id]}
                  style={hidden ? hiddenStyles : {}}
                >
                  <NavItem {...navItem} activeSection={activeSection}>
                    {navItem.transKey ? (
                      <Trans i18nKey={navItem.transKey}>{navItem.transKey}</Trans>
                    ) : (
                      navItem.name
                    )}
                  </NavItem>
                </div>
              );
            })}
            <div ref={moreRef} style={isEmpty(moreItems) ? hiddenStyles : {}}>
              <NavItem
                getIsActive={(activeSec) => indexOf(moreItems, activeSec) !== -1}
                options={moreItems.map((id) => navItems[id])}
                id="more"
              >
                <Trans i18nKey="common:more">More</Trans>
              </NavItem>
            </div>
          </FlexContainer>
        )}
      </div>
    </NavBarContainer>
  );
};

NavBar.defaultProps = {
  activeSection: null,
  uiTheme: {},
};

NavBar.propTypes = {
  activeSection: PropTypes.string,
  club: PropTypes.shape({
    paymentsEnabled: PropTypes.bool,
    teamSections: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  uiTheme: PropTypes.shape(),
};

export default withTheme(NavBar);
