import React from 'react';
import PropTypes from 'prop-types';
import SecondaryPanel from './secondary-panel';

const LevelThree = ({ ...panelProps }) => {
  return <SecondaryPanel {...panelProps} />;
};

LevelThree.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default LevelThree;
