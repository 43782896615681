import React, { useState, useEffect } from 'react';
import Router from 'next/router';
import LoadingBar from 'components/loading-bar';
import isServer from 'lib/is-server';

const SSR = isServer();

const LoadingIndicator = () => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    Router.events.on('routeChangeStart', () => setLoading(true));
    Router.events.on('routeChangeComplete', () => setLoading(false));
    Router.events.on('routeChangeError', () => setLoading(false));
  }, []);

  const style = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
  };

  if (!isLoading && !SSR) {
    return <div />;
  }

  return (
    <div style={style}>
      <LoadingBar isLoading />
    </div>
  );
};

export default LoadingIndicator;
