import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';

const PromotedLinks = ({ links }) => {
  return (
    <Cushion all="large" component="div">
      <Typography preset="tag--large" color="primary">
        <Trans i18nKey="common:club_links">Club Links</Trans>
      </Typography>
      {links.map((link, i) => (
        <Rectangle key={link.id} borderBottom={i === links.length - 1 ? 'none' : 'thinMercury'}>
          <Cushion vertical="medium" component="div">
            <Typography
              preset="subtitle--small"
              color="black"
              component="a"
              href={link.url}
              target={link.target}
              rel="noopener norefferer"
            >
              {link.label}
            </Typography>
          </Cushion>
        </Rectangle>
      ))}
    </Cushion>
  );
};

PromotedLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  ).isRequired,
};
export default PromotedLinks;
