import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Shadow, Space } from '@pitchero/react-ui';
import TokenRedirect from 'components/token-redirect-link/route';
import { openLoginPanel } from 'store/auth/actions';
import { Trans } from 'lib/i18n';

const Login = ({ isAssociated: isAssociatedToClub, isLoading, loggedIn, openPanel }) => {
  if (!isLoading && loggedIn && isAssociatedToClub) {
    return null;
  }

  return (
    <div style={{ width: 240 }} className="footer-login">
      {!isLoading && !loggedIn && (
        <Space bottom="small">
          <Button onClick={openPanel} theme="primaryContrastText" fullWidth>
            <Trans i18nKey="common:login">Login</Trans>
          </Button>
        </Space>
      )}
      {!isLoading && !isAssociatedToClub && (
        <TokenRedirect route="join">
          <Shadow shadow="dark">
            <Button theme="primaryContrast" fullWidth>
              <Trans i18nKey="common:join">Join</Trans>
            </Button>
          </Shadow>
        </TokenRedirect>
      )}
    </div>
  );
};

Login.propTypes = {
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  openPanel: PropTypes.func.isRequired,
};
Login.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
};
const dispatchToProps = {
  openPanel: openLoginPanel,
};
export default connect(null, dispatchToProps)(Login);
