import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, FlexContainer, Show, Space, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import PitcheroLogo from 'components/pitchero-logo';
import RankRibbon from 'components/rank-ribbon';

const ClubRanking = ({ headerContrast, rank, rankGroup }) => (
  <Show minWidth="fullHeader" component="div">
    <Space bottom="xsmall">
      <Typography preset="tag--small" component="div" color="headerContrast">
        <Trans i18nKey="common:powered_by">Powered by</Trans>
      </Typography>
    </Space>
    <a href="https://www.pitchero.com" title="Pitchero">
      <PitcheroLogo width={85} color={headerContrast} />
    </a>
    {rank && (
      <FlexContainer alignItems="center">
        <Typography preset="tag--small" component="div" color="headerContrast">
          <Trans i18nKey="rank" values={{ rank }}>
            {`Rank ${rank}`}
          </Trans>
        </Typography>
        <Cushion left="xxsmall">
          <RankRibbon
            rankGroup={rankGroup}
            defaultColors={{ colorOne: headerContrast, colorTwo: headerContrast }}
          />
        </Cushion>
      </FlexContainer>
    )}
  </Show>
);

ClubRanking.defaultProps = {
  rank: null,
  rankGroup: null,
};

ClubRanking.propTypes = {
  headerContrast: PropTypes.string.isRequired,
  rank: PropTypes.number,
  rankGroup: PropTypes.string,
};

export default React.memo(ClubRanking);
