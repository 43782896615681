import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Shadow, Space, Rectangle } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import Sponsors from 'components/sponsors/list';
import AffiliationsAndLeagueBanner from './affiliations-and-league-banner';
import PitcheroFooter from './pitchero-footer';
import ClubFooter from './club-footer';

const Footer = ({ club, shadow }) => {
  const { affiliations, leagueBanner, sponsors, sport } = club;
  const footerSponsors = sponsors.filter((sponsor) => sponsor.placements.includes('homepage'));

  return (
    <Shadow shadow={shadow}>
      <Rectangle fill="white">
        <footer id="footer">
          {(!isEmpty(affiliations) || !isEmpty(leagueBanner)) && (
            <AffiliationsAndLeagueBanner affiliations={affiliations} leagueBanner={leagueBanner} />
          )}
          {!isEmpty(affiliations) && !isEmpty(footerSponsors) && (
            <Space vertical="0" horizontal="medium">
              <Rectangle component="hr" borderBottom="thinMercury" />
            </Space>
          )}
          {!isEmpty(footerSponsors) && (
            <Sponsors sponsors={footerSponsors} titleTranslationKey="common:club_sponsors" />
          )}
          <ClubFooter club={club} />
          <PitcheroFooter sport={sport} />
        </footer>
      </Rectangle>
    </Shadow>
  );
};

Footer.defaultProps = {
  shadow: null,
};

Footer.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  shadow: PropTypes.string,
};
export default Footer;
