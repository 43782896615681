import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { connect } from 'react-redux';
import { getCurrentClub } from 'store/clubs/selectors';
import { Grid, Shadow, Typography } from '@pitchero/react-ui';
import TeamFrame from 'components/team-frame';
import { SKINNED_WIDTH } from 'lib/advertising/constants';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import TEAM_PROP_TYPES from 'lib/prop-types/team';
import { montserrat } from 'lib/club-theme/typography';
import clubUrl from 'lib/routing/club-url';
import generateClubCrestUrl from 'lib/club-crest-url';
import AppAdBanner from 'components/external-scripts/app-ad-banner';
import DesignBuilder from './design-builder';
import Header from './header';
import Footer from './footer';
import HeaderWrapper from './header/wrapper';

const LoginPanel = dynamic(() => import('components/auth/login-panel'), {
  ssr: false,
});

const NetworkPanelWrapper = dynamic(() => import('./network-panel-wrapper'), {
  ssr: false,
});

const ClubFrame = ({
  activeSection,
  activeSubSection,
  club,
  children,
  pageType,
  themeDraftId,
  team,
}) => {
  const isFreePackage = club.advertisingTier === 1;

  const wrapWithTeamFrame = (content) => {
    if (!team || !activeSubSection) {
      return (
        <Shadow
          shadow={isFreePackage ? 'default' : null}
          className="contentWrapper"
          component="div"
        >
          {content}
        </Shadow>
      );
    }

    const teamSponsors = club.sponsors.filter((sponsor) =>
      sponsor.placements.includes(`team${team.id}`),
    );
    return (
      <TeamFrame
        team={team}
        sponsors={teamSponsors}
        activeSection={activeSubSection}
        isFreePackage={isFreePackage}
      >
        {content}
      </TeamFrame>
    );
  };

  const faviconSizes = [57, 64, 96];

  const wrapWithSkin = (output) => {
    if (!isFreePackage) {
      return output;
    }

    return (
      <div className="js-skins-container">
        <div
          className="js-skins-container-inner"
          style={{
            // Styles added manually as react-ui seemed to be buggy for this.
            backgroundColor: '#F1F1F1',
            maxWidth: SKINNED_WIDTH,
            margin: '0 auto',
            boxShadow: '0 5px 10px 0 rgba(0,0,0,0.05)',
          }}
        >
          {output}
        </div>
      </div>
    );
  };

  return (
    <>
      <Head>
        {faviconSizes.map((size) => (
          <link
            rel="icon"
            key={`favicon${size}`}
            type="image/png"
            href={generateClubCrestUrl(club, size)}
            sizes={`${size}x${size}`}
          />
        ))}
        <link rel="apple-touch-icon" href={generateClubCrestUrl(club, 192)} />
        <link rel="manifest" href={`${clubUrl(club, 'homepage')}manifest.json`} />
        <meta name="theme-color" content={club.colours.primary} />
      </Head>
      {themeDraftId && <DesignBuilder club={club} themeDraftId={themeDraftId} />}
      {wrapWithSkin(
        <Typography component="div" family={montserrat}>
          <Grid rows="auto 1fr auto" columns="minmax(0, 1fr)" style={{ minHeight: '100vh' }}>
            <Shadow shadow={isFreePackage ? 'default' : null}>
              <AppAdBanner club={club} />
              <HeaderWrapper>
                <Header club={club} activeSection={activeSection} pageType={pageType} team={team} />
              </HeaderWrapper>
            </Shadow>
            {wrapWithTeamFrame(<div>{children}</div>)}
            <Footer club={club} shadow={isFreePackage ? 'default' : null} team={team} />
          </Grid>
          <LoginPanel />
          <NetworkPanelWrapper currentClub={club} />
        </Typography>,
      )}
    </>
  );
};

ClubFrame.defaultProps = {
  activeSection: '',
  activeSubSection: null,
  pageType: '',
  team: null,
  themeDraftId: null,
};

ClubFrame.propTypes = {
  activeSection: PropTypes.string,
  activeSubSection: PropTypes.string,
  club: CLUB_PROP_TYPES.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  pageType: PropTypes.string,
  team: TEAM_PROP_TYPES,
  themeDraftId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
});

export default connect(mapStateToProps)(ClubFrame);
