import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, values } from 'lodash';
import { Cushion, Grid, Panel, Rectangle, ScrollBox, Typography } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import ClubLink from 'components/club-link';
import Image from 'components/basic-image/lazy';
import getNavItems from '../nav-items';
import Login from './login';
import NavItem from './nav-item';
import PromotedLinks from './promoted-links';

const LevelOne = ({ club, isOpen, onClose, activeSection, setLevelTwo }) => {
  const [navItems] = useState(
    values(
      getNavItems({
        activeSection,
        club,
        limitTeams: false,
      }),
    ),
    [],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Rectangle fill={!isEmpty(club.promotedLinks) ? 'seashellgrey' : 'white'}>
      <Panel isOpen={isOpen} onClose={onClose} order={1}>
        <Grid rows="auto minmax(0, 1fr)" style={{ maxHeight: '100%' }}>
          <ClubLink route="homepage">
            <a
              onClick={() => {
                onClose();
                return true;
              }}
              onKeyPress={() => {
                onClose();
                return true;
              }}
              role="presentation"
            >
              <Rectangle fill="white">
                <Cushion vertical="medium" left="large" right="xxxlarge">
                  <Grid columnGap="small" columns="32px 1fr" alignItems="center">
                    <Image
                      key={club.id}
                      src={club.logo}
                      alt={club.name}
                      height={90}
                      width={90}
                      quality={80}
                      transformation="square"
                      includeRetina
                    />
                    <Typography preset="headerClubName" color="black">
                      {club.name}
                    </Typography>
                  </Grid>
                </Cushion>
              </Rectangle>
            </a>
          </ClubLink>
          <div>
            <ScrollBox yOnly>
              <Rectangle fill="white" component="div">
                <Login club={club} onClose={onClose} />
              </Rectangle>
              <Cushion component="div" bottom="xxlarge">
                <Rectangle fill="white">
                  <Cushion vertical="small" horizontal="large" component="div">
                    <NavItem
                      id="home"
                      routeParams={{ route: 'homepage' }}
                      icon="HOME"
                      transKey="common:home"
                      onClose={onClose}
                    />
                    {navItems.map((item, i) => {
                      const isLastItem = navItems.length === i + 1;
                      if (item.groupedOptions || item.options) {
                        return (
                          <NavItem
                            {...item}
                            key={item.id}
                            noSeperator={isLastItem}
                            onClick={() =>
                              setLevelTwo({
                                isOpen: true,
                                props: { ...item },
                              })
                            }
                          />
                        );
                      }
                      return (
                        <NavItem
                          {...item}
                          onClose={onClose}
                          key={item.id}
                          noSeperator={isLastItem}
                        />
                      );
                    })}
                  </Cushion>
                </Rectangle>
                {!isEmpty(club.promotedLinks) && <PromotedLinks links={club.promotedLinks} />}
              </Cushion>
            </ScrollBox>
          </div>
        </Grid>
      </Panel>
    </Rectangle>
  );
};

LevelOne.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activeSection: PropTypes.string,
  setLevelTwo: PropTypes.func.isRequired,
};
LevelOne.defaultProps = {
  activeSection: null,
};
export default LevelOne;
