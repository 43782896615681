import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Cushion, FlexContainer, Rectangle, Shadow, Space } from '@pitchero/react-ui';
import TokenRedirect from 'components/token-redirect-link/route';
import { openLoginPanel } from 'store/auth/actions';
import { isLoggedIn } from 'store/auth/selectors';
import { isAssociated, isLoadingNetworkPanel } from 'store/membership/selectors';
import { Trans } from 'lib/i18n';
import AccountLink from '../account-link';

const Login = ({
  openPanel,
  isAssociated: isAssociatedToClub,
  isLoading,
  loggedIn,
  style: customStyle,
}) => {
  if (isLoading || (loggedIn && isAssociatedToClub)) {
    return (
      <Cushion horizontal="large" component="div">
        <Rectangle radius="rounded" fill="alabaster">
          <Cushion vertical="small" horizontal="small" component="div">
            <AccountLink isLoading={isLoading} inSidebar />
          </Cushion>
        </Rectangle>
      </Cushion>
    );
  }

  return (
    <Space horizontal="medium" style={{ textAlign: 'center', ...customStyle }}>
      <FlexContainer>
        {!isLoading && !isAssociatedToClub && (
          <Space horizontal="small" style={{ flexGrow: 1 }}>
            <Shadow shadow="dark" component="div">
              <TokenRedirect route="join">
                <Button theme="primary" fullWidth>
                  <Trans i18nKey="common:join">Join</Trans>
                </Button>
              </TokenRedirect>
            </Shadow>
          </Space>
        )}
        {!isLoading && !loggedIn && (
          <Space horizontal="small" style={{ flexGrow: 1 }}>
            <Button onClick={openPanel} theme="primaryText">
              <Trans i18nKey="common:login">Login</Trans>
            </Button>
          </Space>
        )}
      </FlexContainer>
    </Space>
  );
};

Login.propTypes = {
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  openPanel: PropTypes.func.isRequired,
  style: PropTypes.shape(),
};

Login.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
  style: {},
};

const dispatchToProps = {
  openPanel: openLoginPanel,
};

const mapStateToProps = (state, { club: { id: clubId } }) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state),
  isAssociated: isAssociated(state, clubId),
});

export default connect(mapStateToProps, dispatchToProps)(Login);
