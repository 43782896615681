import React from 'react';
import { Cushion, Typography } from '@pitchero/react-ui';

import { Trans } from 'lib/i18n';

const Address = () => {
  const endYear = new Date().getFullYear();

  return (
    <Typography align="center" responsive={[{ minWidth: 'tab', props: { align: 'right' } }]}>
      <Cushion bottom="small">
        <Typography preset="description" color="footerBgContrast" component="div">
          &copy;
          <Trans i18nKey="common:footer_copyright" values={{ endYear }}>
            {`Pitch Hero Ltd 2008-${endYear}`}
          </Trans>
        </Typography>
      </Cushion>
      <Cushion bottom="small">
        <Typography preset="description" color="footerBgContrast" component="div">
          <Trans i18nKey="common:footer_company_info">
            Pitch Hero Ltd Registered in ENGLAND | WF3 1DR | Company Number - 636 1033
          </Trans>
        </Typography>
      </Cushion>
      <Cushion bottom="medium">
        <Typography preset="description" color="footerBgContrast" component="div">
          <Trans i18nKey="common:footer_digital_advertiser">
            Digital Advertising by Planet Sport Group
          </Trans>
        </Typography>
      </Cushion>
    </Typography>
  );
};

export default Address;
