import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Space } from '@pitchero/react-ui';
import FacebookIcon from 'icons/Facebook';
import InstagramIcon from 'icons/Instagram';
import TwitterIcon from 'icons/Twitter';
import IconWithTheme from 'components/icon-with-theme';

function getIconComponent(iconName) {
  switch (iconName) {
    case 'FACEBOOK':
      return FacebookIcon;
    case 'INSTAGRAM':
      return InstagramIcon;
    default:
      return TwitterIcon;
  }
}

const SocialIcon = ({ iconName, url }) => {
  const IconComponent = getIconComponent(iconName);
  return (
    <a href={url} title={iconName.toLowerCase()}>
      <Space horizontal="small">
        <Rectangle fill="primaryContrast" radius="circle">
          <Cushion all="xsmall" component="span">
            <IconWithTheme>
              <IconComponent
                name={iconName}
                color="primary"
                size={20}
                style={{ verticalAlign: 'middle' }}
              />
            </IconWithTheme>
          </Cushion>
        </Rectangle>
      </Space>
    </a>
  );
};

SocialIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
export default SocialIcon;
