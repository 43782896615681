import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Panel, Rectangle, Shadow, Space, Typography } from '@pitchero/react-ui';
import { i18n, Trans } from 'lib/i18n';
import { languages } from 'lib/i18n/languages';

const LanguageSelectorPanel = ({ isOpen, onClose }) => {
  return (
    <Rectangle fill="seashellgrey">
      <Panel isOpen={isOpen} onClose={onClose}>
        {isOpen && (
          <>
            <Cushion vertical="xlarge" horizontal="medium">
              <Typography preset="subtitle--large" color="black" component="h3" align="center">
                <Trans i18nKey="common:choose_language">Choose a language</Trans>
              </Typography>
            </Cushion>
            {languages.map((language) => {
              const isCurrentLanguage = language.code === i18n.language;
              return (
                <Space all="medium" component="div" key={language.code}>
                  <Shadow shadow={isCurrentLanguage ? 'default' : 'none'}>
                    <Rectangle fill={isCurrentLanguage ? 'white' : 'mercury'} radius="rounded">
                      <Cushion all="medium">
                        <Typography
                          preset="tab--active"
                          color={isCurrentLanguage ? 'black' : 'dovegrey'}
                          component="button"
                          style={{ width: '100%', display: 'block', cursor: 'pointer' }}
                          onClick={() => {
                            onClose();
                            i18n.changeLanguage(language.code);
                          }}
                        >
                          {language.name}
                        </Typography>
                      </Cushion>
                    </Rectangle>
                  </Shadow>
                </Space>
              );
            })}
          </>
        )}
      </Panel>
    </Rectangle>
  );
};

LanguageSelectorPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default LanguageSelectorPanel;
