import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Cushion, Grid, GridItem, Rectangle, Typography } from '@pitchero/react-ui';
import Image from 'components/basic-image/lazy';
import { isLoggedIn } from 'store/auth/selectors';
import {
  isAssociated as isAssociatedSelector,
  isLoadingNetworkPanel,
} from 'store/membership/selectors';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import Login from './login';
import Navigation from './navigation';
import SocialIcon from './social-icon';

const ClubFooter = ({ club, loggedIn, isLoading, isAssociated: isAssociatedToClub }) => {
  const { facebookPage, logo, name, twitterAccount, instagramAccount } = club;
  const hasSocialAccounts = facebookPage || twitterAccount || instagramAccount;
  const isLoginShowing = !isLoading && (!loggedIn || !isAssociatedToClub);
  return (
    <Rectangle fill="primary">
      <Cushion all="xlarge">
        <Grid
          columns="minmax(0, 1fr)"
          columnGap="xlarge"
          rowGap="large"
          alignItems="center"
          justifyItems="center"
          responsive={[
            {
              minWidth: 'tab',
              props: {
                columns: isLoginShowing ? 'repeat(2, auto)' : 'auto',
                rows: isLoginShowing ? 'repeat(2, auto)' : 'auto',
              },
            },
            {
              minWidth: 'lap',
              props: {
                columns: isLoginShowing ? 'auto minmax(0, 1fr) auto' : 'repeat(2, auto)',
                rows: 'auto',
              },
            },
          ]}
        >
          <GridItem
            responsive={[
              {
                minWidth: 'tab',
                maxWidth: 'lap',
                props: {
                  columnStart: '1',
                  columnEnd: isLoginShowing ? '3' : '2',
                },
              },
            ]}
          >
            <Typography align="center" component="div">
              <Cushion component="div" bottom="small">
                <Image
                  src={logo}
                  alt={name}
                  height={44}
                  width={44}
                  quality={80}
                  transformation="absolute"
                  includeRetina
                />
              </Cushion>
              <Typography preset="title" color="primaryContrast">
                {name}
              </Typography>
              {hasSocialAccounts && (
                <Cushion top="medium" component="div">
                  {facebookPage && <SocialIcon iconName="FACEBOOK" url={facebookPage} />}
                  {twitterAccount && (
                    <SocialIcon iconName="TWITTER" url={`https://twitter.com/${twitterAccount}`} />
                  )}
                  {instagramAccount && <SocialIcon iconName="INSTAGRAM" url={instagramAccount} />}
                </Cushion>
              )}
            </Typography>
          </GridItem>
          <GridItem>
            <Navigation club={club} />
          </GridItem>
          <Login
            club={club}
            loggedIn={loggedIn}
            isLoading={isLoading}
            isAssociated={isAssociatedToClub}
          />
        </Grid>
      </Cushion>
    </Rectangle>
  );
};

ClubFooter.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
};
ClubFooter.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
};
const mapStateToProps = (state, { club: { id: clubId } }) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state, clubId),
  isAssociated: isAssociatedSelector(state, clubId),
});

const propsAreEqual = (prevProps, nextProps) => {
  const { club: oldClub, isLoading: wasLoading, isAssociated: wasAssociated } = prevProps;
  const { club: newClub, isLoading, isAssociated } = nextProps;
  return oldClub.id === newClub.id && wasLoading === isLoading && wasAssociated === isAssociated;
};

export default connect(mapStateToProps)(React.memo(ClubFooter, propsAreEqual));
