import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Typography, withTheme } from '@pitchero/react-ui';
import ArrowDownIcon from 'icons/ArrowDown';
import { get } from 'lodash';
import styled from 'styled-components';
import ClubLink from 'components/club-link';
import IconWithTheme from 'components/icon-with-theme';
import Dropdown from './dropdown';

const ActiveIndicator = styled.div`
  outline: none;
  cursor: pointer;
  position: relative;
  &:after {
    background-color: ${(props) => props.color};
    border-radius: 4px;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) => (props.isActive ? '3px' : '0px')};
    transition: height 200ms ease-in-out;
  }
  &:hover {
    &::after {
      height: 3px;
    }
  }
`;

class NavItem extends React.PureComponent {
  render() {
    const {
      activeSection,
      children,
      options,
      groupedOptions,
      routeParams,
      style: customStyle,
      getIsActive,
      id,
      uiTheme,
    } = this.props;

    const primaryContrast = get(uiTheme, 'colors.primaryContrast', '#fff');
    const hasOptions = !!options || !!groupedOptions;

    const content = (
      <Cushion vertical={18} horizontal="medium">
        <ActiveIndicator
          isActive={getIsActive(activeSection)}
          color={primaryContrast}
          style={customStyle}
        >
          <Typography preset="name" color="headerContrast">
            {children}
            {hasOptions && (
              <>
                <IconWithTheme>
                  <ArrowDownIcon preset="name" />
                </IconWithTheme>
              </>
            )}
          </Typography>
        </ActiveIndicator>
      </Cushion>
    );

    if (hasOptions || groupedOptions) {
      return (
        <Dropdown options={options} groupedOptions={groupedOptions}>
          {content}
        </Dropdown>
      );
    }

    return (
      <ClubLink {...routeParams}>
        <a className={`primary-nav__${id}`}>{content}</a>
      </ClubLink>
    );
  }
}

NavItem.propTypes = {
  activeSection: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  getIsActive: PropTypes.func,
  style: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  groupedOptions: PropTypes.arrayOf(PropTypes.shape()),
  routeParams: PropTypes.shape(),
  uiTheme: PropTypes.shape(),
};

NavItem.defaultProps = {
  activeSection: null,
  getIsActive: () => false,
  id: null,
  style: {},
  options: null,
  groupedOptions: null,
  routeParams: {},
  uiTheme: {},
};
export default withTheme(NavItem);
