import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import Image from 'components/basic-image/lazy';

const AffiliationsAndLeagueBanner = ({ affiliations, leagueBanner }) => {
  return (
    <Rectangle fill="white">
      <Cushion
        horizontal="small"
        vertical="xxxxlarge"
        component="div"
        responsive={[{ minWidth: 'tab', horizontal: 'large' }]}
      >
        <Cushion bottom="large">
          <Typography preset="title" color="black" component="h4" align="center">
            <Trans i18nKey="common:affiliations">Affiliations</Trans>
          </Typography>
        </Cushion>
        <div style={{ textAlign: 'center' }}>
          {leagueBanner && (
            <Cushion horizontal="small" bottom="medium" key={leagueBanner.id}>
              <a href={leagueBanner.link} style={{ display: 'inline-block' }}>
                <Image
                  height={60}
                  width={370}
                  transformation="frame"
                  backgroundColor="ffffff"
                  src={leagueBanner.image}
                  alt={leagueBanner.name}
                  includeRetina
                />
              </a>
            </Cushion>
          )}
          {affiliations.map((affiliation) => {
            return (
              <Cushion horizontal="small" bottom="medium" key={affiliation.id}>
                <a href={affiliation.link} style={{ display: 'inline-block' }}>
                  <Image
                    height={60}
                    width={99}
                    transformation="frame"
                    backgroundColor="ffffff"
                    src={affiliation.image}
                    alt={affiliation.name}
                    includeRetina
                  />
                </a>
              </Cushion>
            );
          })}
        </div>
      </Cushion>
    </Rectangle>
  );
};

AffiliationsAndLeagueBanner.defaultProps = {
  leagueBanner: null,
};

AffiliationsAndLeagueBanner.propTypes = {
  affiliations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  leagueBanner: PropTypes.shape(),
};

export default React.memo(AffiliationsAndLeagueBanner, () => true);
