import { useEffect, useCallback, useRef } from 'react';

// Custom react hook
const useOutsideClick = (onClick) => {
  const ref = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (ref.current && ref.current.contains(e.target)) return;

      onClick();
    },
    [onClick, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [handleClick]);

  return ref;
};

export default useOutsideClick;
