import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, Typography } from '@pitchero/react-ui';
import DROPDOWN_OPTIONS_PROPTYPES from 'lib/prop-types/dropdown-options';
import { Trans } from 'lib/i18n';
import Arrow from './arrow';
import Container from './container';
import Option from './option';
import Trigger from './trigger';
import useOutsideClick from './useOutsideClick';

const Dropdown = ({ options, groupedOptions, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);
  const triggerRef = useRef(null);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setShouldShow(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const dropdownRef = useOutsideClick(() => {
    if (isOpen) {
      closeMenu();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setShouldShow(true);
    }
  }, [isOpen]);

  let dropdownStyle = {
    opacity: 0,
    zIndex: -1,
    transition: 'z-index 300ms step-end, opacity 300ms ease-in-out',
  };

  if (shouldShow) {
    dropdownStyle = {
      opacity: 1,
      zIndex: 100,
      transition: 'z-index 300ms step-start, opacity 300ms ease-in-out',
    };
  }

  useEffect(() => {
    const newLeftOffset = triggerRef.current
      ? triggerRef.current.getBoundingClientRect().left
      : null;
    if (leftOffset !== newLeftOffset) {
      setLeftOffset(newLeftOffset);
    }
  });
  return (
    <div style={{ position: 'relative' }}>
      <Trigger ref={triggerRef} onClick={() => (isOpen ? closeMenu() : openMenu())}>
        {children}
      </Trigger>
      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: 'relative',
            ...dropdownStyle,
          }}
        >
          <Arrow bottomAdjustment={6} />
          <Container
            leftOffset={leftOffset}
            topAdjustment={18}
            fullWidth={!!groupedOptions}
            className={groupedOptions ? 'dropdown-container' : ''}
          >
            {options &&
              options.map((option) => (
                <Option key={option.id} {...option} onClick={() => closeMenu()}>
                  {option.transKey ? (
                    <Trans i18nKey={option.transKey}>{option.transKey}</Trans>
                  ) : (
                    option.name
                  )}
                </Option>
              ))}
            {groupedOptions && (
              <Grid
                columns="repeat(auto-fit, minmax(240px, 1fr))"
                columnGap="medium"
                rowGap="large"
              >
                {groupedOptions.map((group) => (
                  <div key={group.id}>
                    <Cushion left="small" bottom="medium">
                      <Typography preset="tab--active" color="primary" component="div">
                        {group.transKey ? (
                          <Trans i18nKey={group.transKey}>{group.transKey}</Trans>
                        ) : (
                          group.name
                        )}
                      </Typography>
                    </Cushion>
                    {group.options.map((option) => (
                      <Option key={option.id} {...option} onClick={() => setIsOpen(false)}>
                        {option.transKey ? (
                          <Trans i18nKey={option.transKey}>{option.transKey}</Trans>
                        ) : (
                          option.name
                        )}
                      </Option>
                    ))}
                  </div>
                ))}
              </Grid>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  options: DROPDOWN_OPTIONS_PROPTYPES,
  groupedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      transKey: PropTypes.string,
      id: PropTypes.string,
      options: DROPDOWN_OPTIONS_PROPTYPES,
    }),
  ),
};
Dropdown.defaultProps = {
  children: null,
  options: null,
  groupedOptions: null,
};
export default Dropdown;
