import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Cushion, FlexContainer, ScrollBox, Space, Show, Typography } from '@pitchero/react-ui';
import IconWithTheme from 'components/icon-with-theme';
import FacebookIcon from 'icons/Facebook';
import TwitterIcon from 'icons/Twitter';
import InstagramIcon from 'icons/Instagram';

const PromotedLinks = ({ facebookPage, links, twitterAccount, instagramAccount }) => {
  return (
    <Show minWidth="fullHeader" display="flex">
      <FlexContainer alignItems="center">
        {facebookPage && (
          <Space right="medium">
            <a href={facebookPage} title="facebook">
              <IconWithTheme>
                <FacebookIcon color="headerContrast" size={18} />
              </IconWithTheme>
            </a>
          </Space>
        )}
        {twitterAccount && (
          <Space right="medium">
            <a href={`https://twitter.com/${twitterAccount}`} title="twitter">
              <IconWithTheme>
                <TwitterIcon color="headerContrast" size={18} />
              </IconWithTheme>
            </a>
          </Space>
        )}
        {instagramAccount && (
          <Space right="medium">
            <a href={instagramAccount} title="instagram">
              <IconWithTheme>
                <InstagramIcon color="headerContrast" size={18} />
              </IconWithTheme>
            </a>
          </Space>
        )}
        {!isEmpty(links) && (
          <Cushion bottom="xsmall">
            <ScrollBox xOnly component="div">
              {links.map((link) => (
                <Cushion right={36} key={link.id}>
                  <Typography
                    preset="tag--large"
                    color="headerContrast"
                    component="a"
                    href={link.url}
                    target={link.target}
                    rel="noopener norefferer nofollow"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {link.label}
                  </Typography>
                </Cushion>
              ))}
            </ScrollBox>
          </Cushion>
        )}
      </FlexContainer>
    </Show>
  );
};

PromotedLinks.defaultProps = {
  facebookPage: null,
  links: [],
  twitterAccount: null,
  instagramAccount: null,
};

PromotedLinks.propTypes = {
  facebookPage: PropTypes.string,
  instagramAccount: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  ),
  twitterAccount: PropTypes.string,
};

export default React.memo(PromotedLinks, () => true);
