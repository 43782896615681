import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Cushion, Rectangle, Typography, withTheme } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';

const Option = ({ children, onClick, routeParams, uiTheme }) => {
  const hoverBgColor = get(uiTheme, 'colors.alabaster', '#E7E7E7');
  return (
    <ClubLink {...routeParams}>
      <a role="link" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
        <Rectangle
          fill="white"
          radius="rounded"
          style={{
            ':hover': { backgroundColor: hoverBgColor },
            transition: 'background-color 300ms ease-in-out',
          }}
        >
          <Cushion all="small">
            <Typography
              preset="subtitle--small"
              color="dovegrey"
              component="div"
              style={{ whiteSpace: 'nowrap', minWidth: 140 }}
            >
              {children}
            </Typography>
          </Cushion>
        </Rectangle>
      </a>
    </ClubLink>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  routeParams: PropTypes.shape(),
  uiTheme: PropTypes.shape(),
};
Option.defaultProps = {
  onClick: () => null,
  routeParams: {},
  uiTheme: {},
};
export default withTheme(Option);
