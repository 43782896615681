import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Cushion, Grid, Rectangle, Show, Typography } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';
import Image from 'components/basic-image';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import TEAM_PROP_TYPES from 'lib/prop-types/team';
import PromotedLinks from './promoted-links';
import NavBar from './nav-bar';
import NavPanelLevelOne from './nav-panel/level-one';
import NavPanelLevelTwo from './nav-panel/level-two';
import NavPanelLevelThree from './nav-panel/level-three';
import LoginBox from './login';
import LoadingIndicator from './loading-indicator';
import SponsorStrip from './sponsor-strip';

const Header = ({ activeSection, club, pageType, team }) => {
  const [navPanelLevelOne, setNavPanelLevelOne] = useState({ isOpen: false, props: {} });
  const [navPanelLevelTwo, setNavPanelLevelTwo] = useState({ isOpen: false, props: {} });
  const [navPanelLevelThree, setNavPanelLevelThree] = useState({ isOpen: false, props: {} });

  const closeAllPanels = () => {
    setNavPanelLevelOne({ isOpen: false, props: {} });
    setNavPanelLevelTwo({ isOpen: false, props: {} });
    setNavPanelLevelThree({ isOpen: false, props: {} });
  };

  const { facebookPage, promotedLinks, twitterAccount, instagramAccount } = club;
  const hasSocialAccounts = facebookPage || twitterAccount || instagramAccount;
  const headerSponsors = club.sponsors.filter((sponsor) => sponsor.placements.includes('header'));

  return (
    <>
      <LoadingIndicator />
      <SponsorStrip
        pageType={pageType}
        leagueSponsor={club.leagueSponsor}
        sponsors={headerSponsors}
        team={team}
      />
      <Rectangle
        fill="headerBgMobile"
        responsive={[{ minWidth: 'fullHeader', props: { fill: 'transparent' } }]}
        component="div"
      >
        <Grid
          className="club-header"
          columns="80px minmax(0, 1fr)"
          alignItems="center"
          responsive={[{ minWidth: 'fullHeader', props: { columns: '140px  minmax(0, 1fr)' } }]}
        >
          <div style={{ justifySelf: 'end' }} className="club-header__logo">
            <ClubLink currentClub={club} route="homepage">
              <a>
                <Cushion
                  component="div"
                  horizontal="medium"
                  responsive={[{ minWidth: 'fullHeader', props: { left: '0', right: 'small' } }]}
                >
                  <Image
                    src={club.logo}
                    alt={club.name}
                    height={90}
                    width={90}
                    quality={80}
                    includeRetina
                    transformation="square"
                  />
                </Cushion>
              </a>
            </ClubLink>
          </div>
          <div className="clubHeader__content">
            <Cushion
              all="small"
              left="0px"
              responsive={[
                {
                  minWidth: 'fullHeader',
                  props: { horizontal: 'xlarge', top: 'large', bottom: '0' },
                },
              ]}
            >
              {(!isEmpty(promotedLinks) || hasSocialAccounts) && (
                <PromotedLinks
                  facebookPage={facebookPage}
                  links={promotedLinks}
                  twitterAccount={twitterAccount}
                  instagramAccount={instagramAccount}
                />
              )}
              <Grid
                columns="auto 1fr"
                alignItems="center"
                justifyItems="space-between"
                className="club-header__title-bar"
                responsive={[
                  {
                    minWidth: 'fullHeader',
                    props: {
                      columns: 'auto 300px',
                    },
                  },
                  {
                    minWidth: 'lap',
                    props: {
                      columns: 'auto 400px',
                    },
                  },
                ]}
              >
                <div>
                  <ClubLink currentClub={club} route="homepage">
                    <a>
                      <Typography
                        component="div"
                        preset="headerClubName"
                        color="headerContrast"
                        style={{ hyphens: 'initial' }}
                      >
                        {club.name}
                      </Typography>
                    </a>
                  </ClubLink>
                </div>
                <LoginBox
                  club={club}
                  onMenuClick={() => setNavPanelLevelOne({ ...navPanelLevelOne, isOpen: true })}
                />
              </Grid>
            </Cushion>

            <Show minWidth="fullHeader" component="div">
              <NavBar activeSection={activeSection} club={club} />
            </Show>
          </div>
        </Grid>
      </Rectangle>
      <NavPanelLevelOne
        isOpen={navPanelLevelOne.isOpen}
        onClose={closeAllPanels}
        club={club}
        activeSection={activeSection}
        isLevelTwoOpen={navPanelLevelTwo.isOpen}
        setLevelTwo={setNavPanelLevelTwo}
      />
      <NavPanelLevelTwo
        {...navPanelLevelTwo}
        onClose={closeAllPanels}
        onBackClick={() => setNavPanelLevelTwo({ isOpen: false, props: {} })}
        setLevelThree={setNavPanelLevelThree}
        lightsOutOpacity={0}
      />
      <NavPanelLevelThree
        {...navPanelLevelThree}
        lightsOutOpacity={0}
        onClose={closeAllPanels}
        onBackClick={() => setNavPanelLevelThree({ isOpen: false, props: {} })}
      />
    </>
  );
};

Header.propTypes = {
  activeSection: PropTypes.string,
  club: CLUB_PROP_TYPES.isRequired,
  pageType: PropTypes.string,
  team: TEAM_PROP_TYPES,
};
Header.defaultProps = {
  activeSection: '',
  pageType: '',
  team: null,
};
export default Header;
