import React from 'react';
import PropTypes from 'prop-types';
import HeaderContext from './context';

const HeaderWrapper = ({ children, style }) => {
  return (
    <HeaderContext.Consumer>
      {({ backgroundColor, backgroundImage, height }) => {
        const containerStyle = {
          backgroundColor,
          height,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: -1,
        };

        const imageStyle = {
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          filter: 'blur(5px)',
          height: '100%',
          opacity: 0.08,
          // transition: 'background-image .75s ease-in-out', DISABLED DUE TO BUG in Chrome where image doesn't change
          width: '100%',
        };

        return (
          <header style={{ backgroundColor: backgroundImage ? null : backgroundColor, ...style }}>
            {height > 0 && (
              <div style={containerStyle} id="js-headerBackgroundImage">
                <div style={imageStyle} />
              </div>
            )}
            {children}
          </header>
        );
      }}
    </HeaderContext.Consumer>
  );
};

HeaderWrapper.defaultProps = {
  style: {},
};

HeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  style: PropTypes.shape(),
};

export default HeaderWrapper;
