import React from 'react';
import PropTypes from 'prop-types';

const Trigger = React.forwardRef(({ onClick, children }, ref) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      style={{ outline: 'none' }}
      ref={ref}
    >
      {children}
    </div>
  );
});

Trigger.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export default Trigger;
