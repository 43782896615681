import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/basic-image/lazy';

const sizeProps = (placement) => {
  if (placement === 'header') {
    return {
      height: 36,
      width: 84,
    };
  }

  if (placement === 'player') {
    return {
      height: 104,
      width: 244,
    };
  }

  return {
    height: 68,
    width: 160,
  };
};

const Sponsor = ({ image, placement, renderDescription, style, title, type, url }) => {
  const wrapWithLink = (content) => {
    if (!url) {
      return <div style={{ display: 'inline-block', ...style }}>{content}</div>;
    }

    return (
      <a
        href={url}
        style={{ display: 'inline-block', ...style }}
        target="_blank"
        rel="noopener noreferrer"
        title={type ? `${type} - ${title}` : title}
      >
        {content}
      </a>
    );
  };

  return (
    <>
      {wrapWithLink(
        <Image
          transformation="frame"
          backgroundColor="ffffff"
          {...sizeProps(placement)}
          src={image}
          alt={type ? `${type} - ${title}` : title}
          includeRetina
          quality={90}
        />,
      )}
      {renderDescription()}
    </>
  );
};

Sponsor.defaultProps = {
  placement: null,
  renderDescription: () => null,
  style: {},
  type: null,
  url: null,
};

Sponsor.propTypes = {
  image: PropTypes.string.isRequired,
  placement: PropTypes.string,
  renderDescription: PropTypes.func,
  style: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
};

export default Sponsor;
