import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button, Cushion, FlexContainer, Hide, Shadow, Show, withTheme } from '@pitchero/react-ui';
import MenuIcon from 'icons/Menu';
import IconWithTheme from 'components/icon-with-theme';
import TokenRedirect from 'components/token-redirect-link/route';
import { openLoginPanel } from 'store/auth/actions';
import { isLoggedIn } from 'store/auth/selectors';
import {
  getLoggedInUser,
  isAssociated as isAssociatedSelector,
  isLoadingNetworkPanel,
} from 'store/membership/selectors';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import AccountLink from './account-link';
import ClubRanking from './ranking';

const LoginBox = ({
  club,
  isAssociated,
  isLoading,
  loggedIn,
  onMenuClick,
  openPanel,
  uiTheme,
  user,
}) => {
  const { rank, rankGroup } = club;
  const headerContrast = get(uiTheme, 'colors.headerContrast', 'white');

  return (
    <FlexContainer alignItems="center" justifyContent="flex-end">
      {!isLoading && !loggedIn && (
        <Show minWidth="fullHeader">
          <Cushion component="div" right="small">
            <Button
              className="login-button"
              onClick={openPanel}
              theme="headerContrastText"
              style={{ whiteSpace: 'nowrap' }}
            >
              <Trans i18nKey="common:login">Login</Trans>
            </Button>
          </Cushion>
        </Show>
      )}
      {!isLoading && !isAssociated && (
        <Show minWidth="fullHeader">
          <Cushion component="div" right="large">
            <TokenRedirect route="join">
              <Shadow shadow="dark">
                <Button theme="headerContrast" style={{ whiteSpace: 'nowrap' }}>
                  <Trans i18nKey="common:join">Join</Trans>
                </Button>
              </Shadow>
            </TokenRedirect>
          </Cushion>
        </Show>
      )}
      {(user || isLoading) && (
        <Cushion
          className="logged-in-avatar"
          component="div"
          right="medium"
          left="medium"
          responsive={[{ minWidth: 'fullHeader', props: { right: 'large', left: '0px' } }]}
        >
          <AccountLink isLoading={isLoading} />
        </Cushion>
      )}
      <ClubRanking rank={rank} rankGroup={rankGroup} headerContrast={headerContrast} />
      <Hide minWidth="fullHeader" component="div">
        <Cushion all="small">
          <IconWithTheme>
            <MenuIcon
              color="headerContrast"
              onClick={onMenuClick}
              style={{ cursor: 'pointer' }}
              size={26}
            />
          </IconWithTheme>
        </Cushion>
      </Hide>
    </FlexContainer>
  );
};

LoginBox.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  openPanel: PropTypes.func.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  uiTheme: PropTypes.shape(),
};

LoginBox.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
  user: null,
  uiTheme: {},
};

const dispatchToProps = {
  openPanel: openLoginPanel,
};

const mapStateToProps = (state, { club: { id: clubId } }) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state),
  isAssociated: isAssociatedSelector(state, clubId),
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps, dispatchToProps)(withTheme(LoginBox));
