import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, Rectangle, Typography } from '@pitchero/react-ui';
import ArrowRightIcon from 'icons/ArrowRight';
import ClubLink from 'components/club-link';
import IconWithTheme from 'components/icon-with-theme';
import { Trans } from 'lib/i18n';
import HomeIcon from 'icons/Home';
import PageIcon from 'icons/Page';
import UserCircleIcon from 'icons/UserCircle';
import TrolleyIcon from 'icons/Trolley';
import AlbumIcon from 'icons/Album';
import CalendarIcon from 'icons/Calendar';
import InfoCircleOutlineIcon from 'icons/InfoCircleOutline';
import PhoneIcon from 'icons/Phone';
import BookteqIcon from 'icons/Bookteq';

const iconNameToComponent = (name) => {
  switch (name) {
    case 'HOME':
      return HomeIcon;
    case 'PAGE':
      return PageIcon;
    case 'USER_CIRCLE':
      return UserCircleIcon;
    case 'TROLLEY':
      return TrolleyIcon;
    case 'ALBUM':
      return AlbumIcon;
    case 'CALENDAR':
      return CalendarIcon;
    case 'INFO_CIRCLE_OUTLINE':
      return InfoCircleOutlineIcon;
    case 'PHONE':
      return PhoneIcon;
    case 'BOOKTEQ':
      return BookteqIcon;
    default:
      return null;
  }
};

const NavItem = ({
  icon,
  name,
  noSeperator,
  onClick,
  routeParams,
  transKey,
  options,
  groupedOptions,
  onClose,
}) => {
  if (!transKey && !name) {
    return null;
  }

  const hasOptions = !!options || !!groupedOptions;
  const IconComponent = iconNameToComponent(icon);

  const content = (
    <Rectangle borderBottom={noSeperator ? 'none' : 'thinLightGrey'}>
      <Cushion vertical="medium">
        <Grid
          columns={IconComponent ? 'auto 1fr auto' : '1fr auto'}
          columnGap="medium"
          alignItems="center"
        >
          {IconComponent && (
            <IconWithTheme>
              <IconComponent size={22} color="primary" />
            </IconWithTheme>
          )}
          <Typography preset="subtitle--small" color="black">
            {transKey ? <Trans i18nKey={transKey}>{transKey}</Trans> : name}
          </Typography>
          {hasOptions && (
            <IconWithTheme>
              <ArrowRightIcon size={11} color="dovegrey" />
            </IconWithTheme>
          )}
        </Grid>
      </Cushion>
    </Rectangle>
  );

  if (hasOptions) {
    return (
      <div onClick={onClick} onKeyPress={onClick} role="presentation" style={{ cursor: 'pointer' }}>
        {content}
      </div>
    );
  }

  return (
    <ClubLink {...routeParams}>
      <a
        onClick={() => {
          onClose();
          return true;
        }}
        onKeyPress={() => {
          onClose();
          return true;
        }}
        role="presentation"
      >
        {content}
      </a>
    </ClubLink>
  );
};

NavItem.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.string,
  onClose: PropTypes.func,
  name: PropTypes.node,
  noSeperator: PropTypes.bool,
  routeParams: PropTypes.shape(),
  transKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  groupedOptions: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
};
NavItem.defaultProps = {
  icon: null,
  image: null,
  name: null,
  noSeperator: false,
  routeParams: {},
  transKey: null,
  options: null,
  groupedOptions: null,
  onClose: () => null,
  onClick: () => null,
};
export default NavItem;
