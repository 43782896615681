export const montserrat = "'Montserrat', 'Gibson', 'Roboto', Arial, Helvetica, sans-serif";
export const robotoCondensed =
  "'Roboto Condensed', 'Gibson', 'Roboto', Arial, Helvetica, sans-serif";

export default {
  presets: {
    title: {
      family: montserrat,
      weight: 'bold',
      size: 22,
      lineHeight: '27px',
      transform: 'uppercase',
    },
    'title--large': {
      family: montserrat,
      weight: 'bold',
      size: 26,
      lineHeight: '29px',
      transform: 'uppercase',
      responsive: [
        {
          minWidth: 'tab',
          props: {
            size: 32,
            lineHeight: '36px',
          },
        },
      ],
    },
    'subtitle--large': {
      family: montserrat,
      weight: 600,
      size: 14,
      lineHeight: '18px',
      responsive: [
        {
          minWidth: 'palm',
          props: {
            size: 18,
            lineHeight: '24px',
          },
        },
      ],
    },
    'staticSubtitle--large': {
      family: montserrat,
      weight: 600,
      size: 18,
      lineHeight: '24px',
    },
    'subtitle--small': {
      family: montserrat,
      weight: 600,
      size: 14,
      lineHeight: '18px',
    },
    'tab--active': {
      family: robotoCondensed,
      weight: 'bold',
      size: 16,
      lineHeight: '19px',
      transform: 'uppercase',
    },
    'tab--inactive': {
      family: robotoCondensed,
      weight: 'bold',
      size: 16,
      lineHeight: '19px',
      transform: 'uppercase',
      opacity: 0.5,
    },
    'tag--large': {
      family: robotoCondensed,
      weight: 'bold',
      size: 13,
      lineHeight: '15px',
      transform: 'uppercase',
    },
    'tag--small': {
      family: robotoCondensed,
      weight: 'bold',
      size: 10,
      lineHeight: '11px',
      transform: 'uppercase',
    },
    description: {
      family: montserrat,
      weight: 500,
      size: 12,
      lineHeight: '14px',
    },
    button: {
      family: montserrat,
      weight: 600,
      size: 14,
      lineHeight: '18px',
      transform: 'uppercase',
    },
    'body--large': {
      family: montserrat,
      weight: 500,
      size: 16,
      lineHeight: '24px',
    },
    'body--small': {
      family: montserrat,
      weight: 500,
      size: 14,
      lineHeight: '21px',
    },
    'body--small-opacity': {
      family: montserrat,
      weight: 500,
      size: 14,
      lineHeight: '21px',
      opacity: 0.75,
    },
    name: {
      family: montserrat,
      weight: 'bold',
      size: 12,
      lineHeight: '15px',
      transform: 'uppercase',
    },
    document: {
      family: montserrat,
      weight: 'bold',
      size: 18,
      lineHeight: '15px',
      transform: 'uppercase',
    },
    headerClubName: {
      family: montserrat,
      weight: 'bold',
      size: 12,
      lineHeight: '15px',
      transform: 'uppercase',
      responsive: [
        {
          minWidth: 'fullHeader',
          props: {
            size: 22,
            lineHeight: '27px',
          },
        },
      ],
    },
    errorCode: {
      size: 120,
      weight: 'bold',
      family: montserrat,
    },
  },
};
