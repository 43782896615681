import React, { useState } from 'react';
import { find } from 'lodash';
import { Cushion, FlexContainer, Grid, Rectangle, Space, Typography } from '@pitchero/react-ui';
import TranslateIcon from 'icons/Translate';
import ArrowDownIcon from 'icons/ArrowDown';
import IconWithTheme from 'components/icon-with-theme';
import PitcheroLogo from 'components/pitchero-logo';
import { i18n, Trans } from 'lib/i18n';
import { languages } from 'lib/i18n/languages';
import PropTypes from 'prop-types';
import LanguageSelectorPanel from './language-selector-panel';
import Navigation from './navigation';
import SEONavigation from './seo-navigation';
import Address from './address';

const PitcheroFooter = ({ sport }) => {
  const [isLanguagePanelOpen, setIsLanguagePanelOpen] = useState(false);
  const currentLanguage = find(languages, { code: i18n.language });
  const sporturl = sport.replace('_', '-');
  const sportname = sport.replace('_', ' ');
  return (
    <>
      <Rectangle fill="footerBg">
        <Cushion horizontal="xlarge" vertical="medium">
          <Grid
            columns="1fr"
            columnGap="large"
            rowGap="large"
            alignItems="center"
            justifyItems="center"
            responsive={[{ minWidth: 'tab', props: { columns: 'auto minmax(0, 1fr) auto' } }]}
          >
            <Typography component="div" align="center">
              <Space bottom="xsmall">
                <Typography preset="tag--small" component="div" color="footerBgContrast">
                  <Trans i18nKey="common:powered_by">Powered by</Trans>
                </Typography>
              </Space>
              <a href="https://www.pitchero.com" title="Pitchero">
                <PitcheroLogo width={85} color="footerBgContrast" />
              </a>
            </Typography>
            <Typography
              className="footer-pitchero-description"
              preset="subtitle--small"
              color="footerBgContrast"
              align="center"
              component="div"
            >
              <a
                href={`https://www.pitchero.com/club-website/features/${sporturl}?utm_source=club&utm_medium=footer`}
              >
                <Trans i18nKey="common:pitchero_description" values={{ sportname }}>
                  Pitchero provides everything you need to run your club online.
                </Trans>
              </a>
              <p>
                <a href="https://join.pitchero.com/clubhouse?utm_source=club&utm_medium=footer">
                  <Trans i18nKey="common:footer_guides">
                    Read our guides to club and sports team management
                  </Trans>
                </a>
              </p>
              <a href="https://www.pitchero.com/pricing?utm_source=club&utm_medium=footer">
                <p>
                  <Trans i18nKey="common:footer_trial">Start your free 30-day trial</Trans>
                </p>
              </a>
              <a href="https://blog.pitchero.com?utm_source=club&utm_medium=footer">
                <p>
                  <Trans i18nKey="common:footer_blog">Visit the Pitchero Blog</Trans>
                </p>
              </a>
            </Typography>
            <Typography
              className="footer-translate"
              preset="button"
              color="footerBgContrast"
              component="button"
              onClick={() => setIsLanguagePanelOpen(true)}
            >
              <FlexContainer alignItems="center">
                <Cushion right="xsmall">
                  <IconWithTheme>
                    <TranslateIcon size={24} />
                  </IconWithTheme>
                </Cushion>
                {currentLanguage && currentLanguage.name}
                <Cushion left="xsmall">
                  <IconWithTheme>
                    <ArrowDownIcon size={12} />
                  </IconWithTheme>
                </Cushion>
              </FlexContainer>
            </Typography>
          </Grid>
        </Cushion>
        <Rectangle borderBottom="solid 1px rgba(255, 255, 255, 0.2)" component="hr" />
        <Cushion
          horizontal="small"
          top="medium"
          bottom="xlarge"
          responsive={[{ minWidth: 'tab', props: { horizontal: 'xlarge' } }]}
        >
          <Grid
            columns="minmax(0, 1fr)"
            columnGap="large"
            rowGap="large"
            alignItems="top"
            responsive={[{ minWidth: 'tab', props: { columns: 'repeat(2, minmax(0, 1fr))' } }]}
          >
            <Navigation color="footerBgContrast" />
            <Address />
          </Grid>
          <Rectangle borderBottom="solid 1px rgba(255, 255, 255, 0.2)" component="hr" />
          <SEONavigation color="footerBgContrast" />
        </Cushion>
      </Rectangle>
      <LanguageSelectorPanel
        isOpen={isLanguagePanelOpen}
        onClose={() => setIsLanguagePanelOpen(false)}
      />
    </>
  );
};

PitcheroFooter.propTypes = {
  sport: PropTypes.string,
};

PitcheroFooter.defaultProps = {
  sport: '',
};

PitcheroFooter.displayName = 'PitcheroFooter';

export default React.memo(PitcheroFooter);
